<template>
    <div class="settingsPayout">
        <Card>
            <h2>{{ $lng.__('lk_webmaster_user_settings', 'Payout system') }}</h2>

            <div class="settingsPayout__current">

                <div class="settingsPayout__current__title">
                    {{ $lng.__('lk_webmaster_user_settings', 'Default Payout System') }}
                </div>

                <div
                    v-if="userPaymentSystems.length"
                    class="settingsPayout__current__item"
                    v-for="userPaymentSystem in userPaymentSystems">
                    <div class="settingsPayout__current__itemTitle">
                        <FormRadio
                            :size="24"
                            :disabled="false"
                            :checked="userPaymentSystem.id === defaultPaymentSystemId"
                            @click="setDefaultPaymentSystem(userPaymentSystem.id)"
                        ></FormRadio>
                        {{ userPaymentSystem.name }}
                    </div>

                    <div
                        class="settingsPayout__current__primary"
                        v-if="userPaymentSystem.id === defaultPaymentSystemId">
                        {{ $lng.__('lk_webmaster_user_settings', 'Primary') }}
                    </div>

                </div>
            </div>

            <button
                v-if="this.$store.getters['auth/isUserApproved']"
                @click="isFormAddPaymentSystemVisible=!isFormAddPaymentSystemVisible"
                class="wv-btn--purpure wv-btn--text">
                {{ $lng.__('lk_webmaster_user_settings', 'Add') }}
            </button>

        </Card>

        <SettingsTabPayoutCreate
            @close="isFormAddPaymentSystemVisible=false"
            v-if="isFormAddPaymentSystemVisible && paymentSystems.length"
        ></SettingsTabPayoutCreate>

    </div>
</template>

<script>
    import SettingsTabPayoutCreate from './SettingsTabPayoutCreate.vue';

    export default {
        name: 'SettingsTabPayout',
        props: [],
        data() {
            return {
                isFormAddPaymentSystemVisible: false
            }
        },
        computed: {
            user() {
                return this.$store.state.auth.user
            },
            paymentSystems() {
                return this.$store.state.webmaster.paymentSystems
            },
            defaultPaymentSystemId() {
                return this.user.default_payment_system_id
            },
            userPaymentSystems() {
                return collect(this.user.payment_systems)
                    .sortBy(system => {
                        return system.id === this.defaultPaymentSystemId ? 0 : 1
                    })
                    .all()
            },
        },
        methods: {
            setDefaultPaymentSystem(id) {
                this.$store.dispatch('auth/saveUser', {...this.user, default_payment_system_id: id}).then(() => {
                    this.$toast.success(this.$lng.__('lk_webmaster_user_settings', 'Default payment system updated'))
                })
            },
        },
        beforeMount() {
            this.$store.dispatch('webmaster/getPaymentSystems')
        },
        components: {
            SettingsTabPayoutCreate,
        }
    }
</script>

<style lang="scss">
    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .settingsPayout{
        max-width: 100%;
        min-width: 100%;
        &__current{
            margin-top: 40px;
            padding-bottom: 20px;
            &__title{
                font-family: $font-primary;
                font-weight: 600;
                color: $color-gray4;
                font-size: 16px;
                line-height: 20px;
            }
            &__item{
                border: 1px solid $color-day-line;
                border-radius: 15px;
                margin-top: 15px;
                min-height: 64px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                &Title{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-family: $font-secondary;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    &>div:first-child{
                        margin-right: 10px;
                    }
                }
            }
            &__primary{
                color: white;
                background: $color-day-black;
                border-radius: 12.5px;
                font-family: $font-secondary;
                font-size: 12px;
                text-transform: uppercase;
                text-align: center;
                padding: 3px 10px;
            }
        }
    }

</style>