<template>
    <Card>
        <h2 class="flex-row-center">
            {{ $lng.__('lk_webmaster_user_settings', 'API') }}
            <div
                class="wv-hlp"
                :data-tippy-content="$lng.__('lk_webmaster_user_settings', 'API settings')"
            ></div>
        </h2>

        <div
            class="settingsApi__token"
            @click="copyTokenToClipboard()">
            <div class="settingsApi__token__label">{{ $lng.__('lk_webmaster_user_settings', 'Token') }}: </div>
            <div class="settingsApi__token__value">{{ user.api_token }}</div>
        </div>

        <div class="settingsApi__description">
            {{ $lng.__('lk_webmaster_user_settings', "Dear Partner! If you would like to generate API traffic using your landing page and\ntransit, please read the attached instructions.") }}
        </div>

        <div class="settingsApi__btns">
            <a
                target="_blank"
                :href="instructionDownloadUrl"
                class="wv-btn--purpure wv-btn--text">
                <SvgUse :width="18" :height="18" id="attach" key="attach"></SvgUse>
                <span class="pl-10">{{ $lng.__('lk_webmaster_user_settings', 'Open instructions') }}</span>
            </a>
            <a
                target="_blank"
                href="https://webvork.com/for_publisers/order.php.zip"
                class="wv-btn--purpure wv-btn--text">
                <SvgUse :width="18" :height="18" id="download" key="download"></SvgUse>
                <span class="pl-10">{{ $lng.__('lk_webmaster_user_settings', 'Download order.php') }}</span>
            </a>
        </div>



    </Card>
</template>

<script>
export default {
    name: 'SettingsTabApi',
    props: [],
    data() {
        return {};
    },
    computed: {
        user() {
            return this.$store.state.auth.user
        },
        instructionDownloadUrl() {
            let lngVersion = this.$lng.lng_code === 'ru' ? 'ru' : 'en'
            return 'https://minio.cdnwv.com/lk.webvork/instructions/instruction_api_'+lngVersion+'.pdf';
        },
    },
    methods: {
        copyTokenToClipboard() {
            WV.copyTextToClipboard(this.user.api_token).then(() => {
                this.$toast.success('Token copied to clipboard')
            })
        },
    },
    mounted() {
        setTimeout(WV.tippyMount, 3000)
    },
};
</script>

<style lang="scss">
    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .settingsApi{
        &__token{
            display: flex;
            flex-direction: column;
            border-radius: 12px;
            margin-top: 30px;
            background: $color-day-bg;
            font-weight: bold;
            cursor: pointer;
            @include break(md) {
                height: 50px;
                flex-direction: row;
            }
            &__label{
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                background: white;
                padding: 0 25px;
                border: 2px solid $color-day-bg;
                user-select: none;
                height: 40px;
                @include break(md) {
                    height: auto;
                }
            }
            &__value{
                display: flex;
                align-items: center;
                justify-content: center;
                word-break: break-all;
                margin: 0 10px;
                padding: 10px 20px;
                line-height: 120%;
                text-align: center;
                @include break(lg) {
                    height: auto;
                    padding: 0;
                }
            }
        }
        &__description{
            padding: 40px 0 20px;
            font-family: $font-secondary;
            font-size: 14px;
            line-height: 18px;
            color: $color-day-lmenu-active;
            letter-spacing: 0.5px;
        }
        &__btns{
            display: flex;
            flex-direction: column;
            width: 100%;
            @include break(md) {
                flex-direction: row;
            }
            a{
                white-space: nowrap;
                @include break(md) {
                    margin-right: 20px;
                }
                &:first-child{
                    margin-bottom: 20px;
                    @include break(md) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
</style>