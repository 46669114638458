<template>
    <div class="settingsUserDetails">

        <Card>

            <Loading v-if="!user"></Loading>

            <template v-if="user">
                <h2 class="mb-50">{{ $lng.__('lk_webmaster_user_settings', 'User details') }}</h2>

                <div class="grid">
                    <div class="row">
                        <div class="c-md-6 mb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_user_settings', 'First name')"
                                :placeholder="$lng.__('lk_webmaster_user_settings', 'Enter your first name')"
                                v-model="user.first_name"
                                :validations="[
                                  { rule: validations.isStringLengthSmallThan(user.first_name, 64), message: 'First name must be less than 64 symbols' },
                                ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 mb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_user_settings', 'Last name')"
                                :placeholder="$lng.__('lk_webmaster_user_settings', 'Enter your last name')"
                                v-model="user.last_name"
                                :validations="[
                                  { rule: validations.isStringLengthSmallThan(user.last_name, 64), message: 'Last name must be less than 64 symbols' },
                                ]"
                            ></FormInput>
                        </div>
                    </div>
                </div>




                <h3 class="mb-40">
                    {{ $lng.__('lk_webmaster_user_settings', 'Language') }}
                </h3>

                <FormSelect
                    :label="$lng.__('lk_webmaster_user_settings', 'Select language')"
                    :showResetOption="false"
                    :withIcons="true"
                    v-model:selected="userLanguage"
                    :options="$lng.languages"
                ></FormSelect>

                <h3 class="mb-40 mt-40">
                    {{ $lng.__('lk_webmaster_user_settings', 'Online presence') }}
                </h3>


                <div class="grid">
                    <div class="row ">
                        <div class="c-md-6 mb-40">
                            <FormInput
                                label="Telegram"
                                placeholder="@account"
                                :withIcon="true"
                                iconId="telegramLogoColor"
                                v-model="user.telegram"
                                :validations="[
                            { rule: validations.isStringLengthSmallThan(user.telegram, 254), message: 'Telegram account must be small than 255 symbols' },
                        ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 mb-40">
                            <FormInput
                                label="Whatsapp"
                                placeholder="@account"
                                :withIcon="true"
                                iconId="whatsappLogoColor"
                                v-model="user.whatsapp"
                                :validations="[
                                    { rule: validations.isStringLengthSmallThan(user.whatsapp, 254), message: 'Whatsapp account must be small than 255 symbols' },
                                ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 mb-40">
                            <FormInput
                                label="Skype"
                                placeholder="Enter your skype account"
                                :withIcon="true"
                                iconId="skypeLogoColor"
                                v-model="user.skype"
                                :validations="[
                                    { rule: validations.isStringLengthSmallThan(user.skype, 254), message: 'Skype account must be small than 255 symbols' },
                                ]"
                            ></FormInput>
                        </div>
                        <div class="c-md-6 mb-40">
                            <FormInput
                                :label="$lng.__('lk_webmaster_user_settings', 'Website')"
                                placeholder="https://"
                                v-model="user.website"
                                :validations="[
                                    { rule: validations.isStringLengthSmallThan(user.website, 254), message: 'Website url must be small than 255 symbols' },
                                ]"
                            ></FormInput>
                        </div>
                    </div>
                </div>


                <div class="card__separator mt-30 mb-30"></div>

                <h2 class="mb-20 flex-row-center">
                    {{ $lng.__('lk_webmaster_user_settings', 'IP Access Control') }}
                    <div
                        class="wv-hlp"
                        :data-tippy-content="$lng.__(
                            'lk_webmaster_user_settings',
                            'IP access control used as an additional check during authorization. User being logged out automatically in case if IP address was changed. This is to ensure the best security for your data.'
                         )"
                    ></div>
                </h2>

                <FormSwitch
                    :label="$lng.__('lk_webmaster_user_settings', 'Enable IP access control')"
                    v-model:checked="user.ip_control"
                ></FormSwitch>

                <div class="card__separator mt-30 mb-30"></div>

                <h2 class="mb-20 flex-row-center">
                    {{ $lng.__('lk_webmaster_user_settings', 'News') }}
                    <div
                        class="wv-hlp"
                        :data-tippy-content="$lng.__('lk_webmaster_user_settings', 'Mailing lists management')"
                    ></div>
                </h2>

                <FormSwitch
                    :label="$lng.__('lk_webmaster_user_settings', 'Subscribe to newsletters')"
                    v-model:checked="user.subscribed"
                ></FormSwitch>

                <div class="card__separator mt-20 mb-30"></div>

                <div class="flex-right">
                    <button
                        @click="saveUser()"
                        class="wv-btn--green wv-btn--text">
                        {{ $lng.__('lk_webmaster_user_settings', 'Save') }}
                    </button>
                </div>
            </template>

        </Card>

        <div class="mt-30">
            <ReferalLink></ReferalLink>
        </div>

    </div>
</template>

<script>

import ReferalLink from '../Widgets/ReferalLink';

export default {
    name: 'SettingsTabUserDetails',
    props: [],
    data() {
        return {
            temp: {
                display_name: '',
            }
        }
    },
    computed: {
        userLanguage: {
            get() {
                return this.user.language;
            },
            set(val) {
                this.$store.dispatch('auth/saveUser', {...this.user, language: val})
                    .then(() => {
                        this.$toast.success(this.$lng.__('lk_webmaster_user_settings','Successfully saved'));
                        this.$lng.setLanguage(val);
                    });
            }
        },
        user() {
            return this.$store.state.auth.user;
        },
        validations() {
            return WV.validations
        }
    },
    watch: {

    },
    methods: {
        saveUser() {
            this.$store.dispatch('auth/saveUser', this.user).then(() => {
                this.$toast.success(this.$lng.__('lk_webmaster_user_settings','Successfully saved'))
            })
        }
    },
    beforeUnmount() {
        this.$store.dispatch('auth/getUser')
    },
    mounted() {
        setTimeout(WV.tippyMount, 3000)
    },
    components: {
        ReferalLink,
    }
};
</script>

<style lang="scss">
    .settingsUserDetails{
        width: 100%;
    }

</style>