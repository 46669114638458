<template>
    <div class="settings">

        <div class="settingsTabs">
            <div
                @click="activeTab='details'"
                :class="{active:activeTab==='details'}"
                class="settingsTabs__item">
                {{ $lng.__('lk_webmaster_user_settings', 'User details') }}
            </div>
            <div
                @click="activeTab='payout'"
                :class="{active:activeTab==='payout'}"
                class="settingsTabs__item">
                {{ $lng.__('lk_webmaster_user_settings', 'Payout system') }}
            </div>
            <div
                @click="activeTab='api'"
                :class="{active:activeTab==='api'}"
                class="settingsTabs__item">
                {{ $lng.__('lk_webmaster_user_settings', 'API') }}
            </div>
            <div
                @click="activeTab='postback'"
                :class="{active:activeTab==='postback'}"
                class="settingsTabs__item">
                {{ $lng.__('lk_webmaster_user_settings', 'Postback') }}
            </div>
        </div>

        <div class="settings__wrapper">
            <div class="settingsTabs__content">

                <SettingsTabUserDetails
                    v-if="activeTab==='details'"
                ></SettingsTabUserDetails>

                <SettingsTabPayout
                    v-if="activeTab==='payout'"
                ></SettingsTabPayout>

                <SettingsTabApi
                    v-if="activeTab==='api'"
                ></SettingsTabApi>

                <SettingsTabPostback
                    v-if="activeTab==='postback'"
                ></SettingsTabPostback>

            </div>

            <SettingsSidebar></SettingsSidebar>
        </div>



    </div>
</template>

<script>
    import SettingsSidebar from './SettingsSidebar.vue';
    import SettingsTabUserDetails from './SettingsTabUserDetails';
    import SettingsTabApi from './SettingsTabApi';
    import SettingsTabPayout from './SettingsTabPayout';
    import SettingsTabPostback from './SettingsTabPostback';


    export default {
        name: 'Settings',
        props: [],
        data() {
            return {
                activeTab: 'details',
            }
        },
        computed: {},
        methods: {},
        beforeMount() {
        },
        components: {
            SettingsSidebar,
            SettingsTabUserDetails,
            SettingsTabApi,
            SettingsTabPayout,
            SettingsTabPostback
        }
    }
</script>

<style lang="scss">
    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .settings{
        width: 100%;
        margin-top: 100px;
        @include break(md) {
            margin-top: 0;
        }

        &__wrapper{
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            @include break(xl) {
                flex-direction: row;
            }
        }
        &Tabs{
            max-width: 100%;
            flex: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 30px;
            flex-wrap: nowrap;
            overflow-x: auto;
            @extend .noScrollbar;
            &__item{
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                padding: 0 30px;
                border-bottom: 2px solid transparent;
                font-weight: 600;
                font-family: $font-primary;
                color: $color-day-lmenu-text;
                cursor: pointer;
                transition: 100ms color ease;
                @extend .fix-montserrat;
                &:hover{
                    color: $color-day-lmenu-active;
                }
                &.active{
                    color: $color-day-lmenu-active;
                    border-bottom: 2px solid $color-day-light-green;
                    cursor: default;
                }
            }
            &__content{
                margin-bottom: 30px;
                width: 100%;
                @include break(xl) {
                    width: calc(100% - 400px - 30px);
                    margin-right: 30px;
                }
            }
        }
    }
</style>