<template>
    <div class="referalLink">
        <div class="referalLink__head">
            <h2>{{ $lng.__('lk_webmaster_user_settings', 'Referal program') }}</h2>
            <p>{{ $lng.__('lk_webmaster_user_settings', 'By attracting new webmasters through this link\", you will receive 5% of their income for\nsix months!\"') }}</p>
        </div>

        <div class="referalLink__body">
            <div class="referalLink__controls">
                <FormInput
                    :label="$lng.__('lk_webmaster_user_settings', 'Referal personal link')"
                    @click="copyLinkToClipboard()"
                    :disabled="true"
                    v-model="link"
                ></FormInput>
                <button
                    @click="copyLinkToClipboard()"
                    class="wv-btn--purpure wv-btn--text">
                    {{ $lng.__('lk_webmaster_user_settings', 'Copy link') }}
                </button>
            </div>
            <div class="referalLink__description">
                {{ $lng.__('lk_webmaster_user_settings', "Copy the unique address of the page where your friends can sign up for Webvōrk and\nget bonuses.") }}
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'ReferalLink',
        computed: {
            link() {
                return this.$store.state.auth.user.ref_url || ''
            }
        },
        methods: {
            copyLinkToClipboard() {
                WV.copyTextToClipboard(this.link).then(() => {
                    this.$toast.success('Link copied to clipboard')
                })
            }
        }
    }
</script>

<style lang="scss">
    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .referalLink{
        background: linear-gradient(135deg, #183541 0%, #1F6152 100%);
        border-radius: 10px;
        padding: 2px;
        &__head{
            padding: 30px 26px 20px;
            color: white;
            h2{}
            p{
                padding-top: 10px;
                font-size: 14px;
                line-height: 18px;
            }
        }
        &__body{
            background: white;
            border-radius: 10px;
            padding: 30px 26px 20px;
        }
        &__controls{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
            @include break(sm){
                flex-direction: row;
            }
            button{
                white-space: nowrap;
                width: 100%;
                margin-top: 15px;
                @include break(sm){
                    width: auto;
                    margin-top: 0;
                    margin-left: 20px;
                }
            }
        }
        &__description{
            padding-top: 10px;
            font-size: 14px;
            line-height: 18px;
            color: $color-gray2;
        }
    }
</style>