<template>
    <div class="settingsSidebar">
        <div class="settingsSidebar__wrapper">

            <div class="settingsSidebar__cover">
                <Card>

                    <Loading v-if="!user"></Loading>

                    <template v-if="user">
                        <div v-if="avatar" class="settingsSidebar__avatar">
                            <AvatarLetter
                                :size="160"
                                :words="preparedAvatarWords"
                            ></AvatarLetter>
                        </div>
                        <div class="settingsSidebar__username">
                            {{ user.username }}
                        </div>
                        <div class="settingsSidebar__email">
                            {{ user.email }}
                        </div>
                        <div class="settingsSidebar__role">
                            <div class="settingsSidebar__role__name">
                                {{ user.role }}
                            </div>
                            <div class="settingsSidebar__role__id">
                              {{ user.id }}
                            </div>
                        </div>
                    </template>

                </Card>
            </div>

            <DashboardManager></DashboardManager>

        </div>

<!--        <div class="settingsSidebar__gravatar">
            <div class="settingsSidebar__gravatar__btn">
                <SvgUse  :width="36" :height="36" id="gravatarLogoColor"></SvgUse>
                <a
                    href="https://ru.gravatar.com/"
                    target="_blank"
                    class="wv-btn&#45;&#45;blue wv-btn&#45;&#45;block">
                    Gravatar.com
                </a>
            </div>
            <div class="settingsSidebar__gravatar__notice">
                Для добавления изображения профиля зарегистрируйтесь в gravatar.com через адрес электронной почты указанный на сайте webvork.
            </div>
        </div>-->



    </div>
</template>

<script>
    import DashboardManager from '../Dashboard/DashboardManager.vue'

    export default {
        name: 'SettingsSidebar',
        props: [],
        data() {
            return {}
        },
        computed: {
            user() {
                return this.$store.state.auth.user
            },
            avatar() {
                return this.$store.state.webmaster.statMini.img_url || false
            },
            preparedAvatarWords() {
                let words = [];
                let firstName = typeof this.user.first_name !== 'object' && this.user.first_name !== ''
                    ? this.user.first_name.replaceAll(' ', '')
                    : ''
                let lastName = typeof this.user.last_name !== 'object' && this.user.last_name !== ''
                    ? this.user.last_name.replaceAll(' ', '')
                    : ''
                firstName+lastName !== ''
                    ? words.push(firstName+' '+lastName)
                    : words.push(this.user.username.replaceAll(' ', ''))
                return words
            }

        },
        methods: {},
        beforeMount() {
        },
        components: {
            DashboardManager,
        }
    }
</script>

<style lang="scss">
    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .settingsSidebar{
        flex: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        @include break(xl) {
            width: 400px;
        }
        @include break(xxl) {
            width: 400px;
        }
        &__wrapper{
            display: flex;
            flex-direction: column;
            @include break(lg) {
                flex-direction: row;
                align-items: stretch;
            }
            @include break(xl) {
                flex-direction: column;
            }
        }
        &__cover{
            width: 100%;
            margin-bottom: 30px;
            @include break(lg) {
                margin-right: 20px;
                margin-bottom: 0;
            }
            @include break(xl) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        &__avatar{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 165px;
                height: 165px;
            }
        }
        &__username{
            padding-top: 30px;
            width: 100%;
            font-family: $font-primary;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            letter-spacing: 0.005em;
            color: $color-day-lmenu-active;
        }
        &__email{
            padding-top: 5px;
            font-family: $font-secondary;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.5px;
            color: $color-day-lmenu-active;
        }
        &__role{
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            height: 38px;
            border-radius: 12px;
            margin-top: 15px;
            background: $color-day-bg;
            color: $color-gray2;
            font-family: $font-secondary;
            font-weight: 400;
            font-size: 12px;
            text-transform: uppercase;
            max-width: 260px;
            &__name{
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 12px;
                padding: 0 24px;
                background: $color-day-white;
                border:1px solid $color-day-bg;
                user-select: none;
            }
            &__id{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                text-align: center;
            }
        }

        &__gravatar{
            order:3;
            padding: 30px;
            &__btn{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                a{
                    margin-left: 30px;
                    border-color: $color-gray2;
                }
            }
            &__notice{
                padding-top: 30px;
                font-family: $font-secondary;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: $color-gray2;
            }
        }
    }
</style>