<template>
    <div class="settingsPostback">

        <Card>
            <h2>{{ $lng.__('lk_webmaster_user_settings', 'Postback') }}</h2>
            <div class="settingsPostback__legend">
                <div class="table__wrapper">
                    <table class="table">
                        <tr>
                            <td>{lead_guid}</td>
                            <td>{{ $lng.__('lk_webmaster_user_settings', 'Order ID (36 character string)') }}</td>
                        </tr>
                        <tr>
                            <td>{status_lead}</td>
                            <td>{{ $lng.__('lk_webmaster_user_settings', 'Status') }}</td>
                        </tr>
                        <tr>
                            <td>{utm_source}</td>
                            <td>{{ $lng.__('lk_webmaster_user_settings', 'Stream ID') }}</td>
                        </tr>
                        <tr>
                            <td>{utm_medium}</td>
                            <td>Utm Medium</td>
                        </tr>
                        <tr>
                            <td>{utm_campaign}</td>
                            <td>Utm Campaign</td>
                        </tr>
                        <tr>
                            <td>{utm_content}</td>
                            <td>Utm Content</td>
                        </tr>
                        <tr>
                            <td>{utm_term}</td>
                            <td>Utm Term</td>
                        </tr>
                        <tr>
                            <td>{money}</td>
                            <td>{{ $lng.__('lk_webmaster_user_settings', 'Money') }}</td>
                        </tr>
                        <tr>
                            <td>{currency}</td>
                            <td>{{ $lng.__('lk_webmaster_user_settings', 'Currency') }}</td>
                        </tr>
                        <tr>
                            <td>{{ $lng.__('lk_webmaster_user_settings', 'Example') }}</td>
                            <td>https://webvork.com/ru/postback/test?lead_guid={lead_guid}&status_lead={status_lead}&utm_source={utm_source}&utm_medium={utm_medium}&utm_campaign={utm_campaign}&utm_content={utm_content}&utm_term={utm_term}&money={money}&cy={currency}</td>
                        </tr>
                    </table>
                </div>
            </div>

        </Card>

        <Card addclass="mt-15">

            <h2>{{ $lng.__('lk_webmaster_user_settings', 'Global postback settings') }}</h2>

            <div class="settingsPostback__line mt-50">
                <FormInput
                    :label="$lng.__('lk_webmaster_user_settings', 'Create leads')"
                    placeholder=""
                    v-model="user.postback_settings.lead_create"
                    :validations="[
                        { rule: validations.isStringLengthSmallThan(user.postback_settings.lead_create, 254), message: 'Url must be small than 255 symbols' },
                    ]"
                ></FormInput>
                <button
                    v-if="getPostbackIdByTrigger('lead_create')"
                    @click="$router.push('/'+this.$route.params.lng+'/webmaster/postback-logs?postback_id='+getPostbackIdByTrigger('lead_create'))"
                    class="wv-btn--gray wv-btn--text">
                    Logs
                </button>
            </div>

            <div class="settingsPostback__line">
                <FormInput
                    :label="$lng.__('lk_webmaster_user_settings', 'Confirm leads')"
                    placeholder=""
                    v-model="user.postback_settings.lead_confirm"
                    :validations="[
                        { rule: validations.isStringLengthSmallThan(user.postback_settings.lead_confirm, 254), message: 'Url must be small than 255 symbols' },
                    ]"
                ></FormInput>
                <button
                    v-if="getPostbackIdByTrigger('lead_confirm')"
                    @click="$router.push('/'+this.$route.params.lng+'/webmaster/postback-logs?postback_id='+getPostbackIdByTrigger('lead_confirm'))"
                    class="wv-btn--gray wv-btn--text">
                    Logs
                </button>
            </div>


            <div class="settingsPostback__line">
                <FormInput
                    :label="$lng.__('lk_webmaster_user_settings', 'Reject Lead')"
                    placeholder=""
                    v-model="user.postback_settings.lead_reject"
                    :validations="[
                    { rule: validations.isStringLengthSmallThan(user.postback_settings.lead_reject, 254), message: 'Url must be small than 255 symbols' },
                ]"
                ></FormInput>
                <button
                    v-if="getPostbackIdByTrigger('lead_reject')"
                    @click="$router.push('/'+this.$route.params.lng+'/webmaster/postback-logs?postback_id='+getPostbackIdByTrigger('lead_reject'))"
                    class="wv-btn--gray wv-btn--text">
                    Logs
                </button>
            </div>

            <div class="settingsPostback__line">
                <FormInput
                    :label="$lng.__('lk_webmaster_user_settings', 'Send link to trash')"
                    placeholder=""
                    v-model="user.postback_settings.lead_trash"
                    :validations="[
                        { rule: validations.isStringLengthSmallThan(user.postback_settings.lead_trash, 254), message: 'Url must be small than 255 symbols' },
                    ]"
                ></FormInput>
                <button
                    v-if="getPostbackIdByTrigger('lead_trash')"
                    @click="$router.push('/'+this.$route.params.lng+'/webmaster/postback-logs?postback_id='+getPostbackIdByTrigger('lead_trash'))"
                    class="wv-btn--gray wv-btn--text">
                    Logs
                </button>
            </div>

            <div class="flex-left">
                <button
                    @click="saveUser()"
                    class="wv-btn--green wv-btn--text">
                    {{ $lng.__('lk_webmaster_user_settings', 'Save') }}
                </button>
            </div>

        </Card>

    </div>
</template>

<script>
export default {
    name: 'SettingsTabPostback',
    props: [],
    data() {
        return {};
    },
    computed: {
        user() {
            return this.$store.state.auth.user;
        },
        validations() {
            return WV.validations;
        },
        postbacks() {
            return this.$store.state.webmaster.postbacks;
        },
        globalPostbacks() {
            return collect(this.postbacks).where('stream_id', null).all()
        },
    },
    methods: {
        saveUser() {
            this.$store.dispatch('auth/saveUser', this.user)
                .then(() => {
                    this.$toast.success('Postback settings updated');
                    this.$store.dispatch('webmaster/getPostbacks');
                });
        },
        getPostbackIdByTrigger(trigger) {
            let postback = collect(this.globalPostbacks).where('trigger', trigger).first();
            return postback ? postback.id : false;
        },
    },
    beforeMount() {
        this.$store.dispatch('webmaster/getPostbacks');
    },
    beforeUnmount() {
        this.$store.dispatch('auth/getUser');
    }
};
</script>

<style lang="scss">

@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.settingsPostback{
    &__line{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 40px;
        @include break(xs) {
            flex-direction: row;
            align-items: center;
        }
        button{
            margin-top: 10px;
            @include break(xs) {
                margin-top: 0;
                margin-left: 20px;
            }
        }
    }
    &__legend {
        padding-top: 30px;
        margin: 0 -30px 0;
        table {
            tr {
                td {
                    border-bottom: 1px solid $color-day-line;
                    padding: 18px 20px;
                    vertical-align: center;
                    font-family: $font-secondary;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0.5px;
                    font-weight: normal;
                    &:first-child {
                        font-weight: bold;
                        padding-right: 20px;
                        padding-left: 30px;
                    }
                    &:last-child {
                    }
                }
                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

</style>